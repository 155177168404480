/* global Swiper, anime, fetchSvgInline */

/* ---------------------------------------- [START] isInViewport */
function isInViewport(el) {
	var rect = el.getBoundingClientRect();

	var isVisible = el.offsetHeight !== 0;

	return (
		isVisible &&
		rect.bottom >= 0 &&
		rect.right >= 0 &&
		rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
		rect.left <= (window.innerWidth || document.documentElement.clientWidth)
	);
}
/* ---------------------------------------- [END] isInViewport */

/* ---------------------------------------- [START] 簡單的節流函數 */
// https://www.cnblogs.com/coco1s/p/5499469.html
function throttle(func, wait, mustRun) {
	var timeout;
	var startTime = new Date();

	return function () {
		var context = this;
		var args = arguments;
		var curTime = new Date();

		if (timeout !== undefined) {
			if (window.requestTimeout !== undefined) clearRequestTimeout(timeout);
			else clearTimeout(timeout);
		}
		// 如果達到了規定的觸發時間間隔，觸發 handler
		if (curTime - startTime >= mustRun) {
			func.apply(context, args);
			startTime = curTime;
			// 沒達到觸發間隔，重新設定定時器
		} else {
			if (window.requestTimeout !== undefined) timeout = requestTimeout(func, wait);
			else timeout = setTimeout(func, wait);
		}
	};
}
/* ---------------------------------------- [END] 簡單的節流函數 */

(function (window, document) {
	document.getElementById('header').classList.add('transparent');
	document.querySelector('html').classList.add('page-index');

	/* ---------------------------------------- [START] 整頁 Scroll 監測 (Before) */
	var pageScrollCheckList = []; // 紀錄物件
	var pageScrollAdd = function (selector, buildFunction) {
		var el = document.querySelector(selector);
		if (el === null) {
			return false;
		}
		pageScrollCheckList.push({
			build: false,
			el: el,
			fun: buildFunction,
		});
	};
	var pageScrollClean = function () {
		pageScrollCheckList = Array.prototype.filter.call(pageScrollCheckList, function (item) {
			return item.build === false;
		});
	};
	/* ---------------------------------------- [END] 整頁 Scroll 監測 (Before) */

	/* ---------------------------------------- [START] Category  */
	const cateItems = document.querySelectorAll('.index-cate__item');
	const cateActiveClass = 'is-active';
	let cateActive = 0;
	cateItems.forEach((item, index) => {
		item.addEventListener('click', function () {
			if (cateActive !== index) {
				cateItems[cateActive].classList.remove(cateActiveClass);
				this.classList.add(cateActiveClass);
				cateActive = index;
			}
		});
		item.querySelector('a').addEventListener('click', function (e) {
			if (window.innerWidth >= 1024 && cateActive !== index) {
				e.preventDefault();
			}
		});
	});
	/* ---------------------------------------- [END] Category  */

	/* ---------------------------------------- [START] Featured Products  */
	const indexFeatureCarousel = '#index-feature-carousel';

	function featureBuild() {
		const swiperTarget = indexFeatureCarousel;
		const swiperEl = document.querySelector(swiperTarget).parentElement;
		const swiperPageEl = swiperEl.querySelector('.swiper-pagination');
		const swiperNextEl = swiperEl.querySelector('.swiper-button-next');
		const swiperPrevEl = swiperEl.querySelector('.swiper-button-prev');
		const slidesCount = swiperEl.querySelectorAll('.swiper-slide').length;

		const swiper = new Swiper(swiperTarget, {
			effect: 'fade',
			loop: slidesCount > 1,
			watchOverflow: true,
			autoHeight: true,
			lazy: true,
			preloadImages: false,
			pagination: {
				el: swiperPageEl,
				type: 'fraction',
			},
			navigation: {
				nextEl: swiperNextEl,
				prevEl: swiperPrevEl,
			},
		});
	}
	window.addEventListener('load', function () {
		pageScrollAdd(indexFeatureCarousel, featureBuild);
	});
	/* ---------------------------------------- [END] Featured Products */

	/* ---------------------------------------- [START] AD Popup */
	function buildEventPopup() {
		var $popupEventEl = $('#popup-event');

		if (!$popupEventEl.length) {
			return false
		}

		// 加在<html>上
		var getScreenHeight = function() {
			document.documentElement.style.setProperty('--screen-height', $(window).height() + "px");
		}

		// 讀取內容高度
		var getContHeight = function() {
			$popupEventEl.get(0).style.setProperty('--cont-height', $popupEventEl.find('.reveal-event__cont').innerHeight() + "px");
		}

		var eventPopupResize = function() {
			getScreenHeight();
			getContHeight();
		}
		var eventPopupResizeThrottle = throttle(eventPopupResize, 50, 200); // 節流作用

		$popupEventEl.on('closeme.zf.reveal', getScreenHeight);
		$popupEventEl.on('open.zf.reveal', getContHeight);
		window.addEventListener('resize', eventPopupResizeThrottle);
	}

	window.addEventListener('load', function () {
		buildEventPopup();
	});
	
	/* ---------------------------------------- [END] AD Popup */

	/* ---------------------------------------- [START] 整頁 Scroll 監測 (After) */
	var pageScrollThrottle = null;
	var pageScrollHandler = function () {
		if (pageScrollCheckList.length === 0) {
			return false;
		} // 因為改成 Page Global 執行，所以不會停止

		for (var i = 0; i < pageScrollCheckList.length; i++) {
			if (isInViewport(pageScrollCheckList[i].el)) {
				pageScrollCheckList[i].fun();
				pageScrollCheckList[i].build = true;
			}
		}
		pageScrollClean();
		// if (pageScrollCheckList.length === 0) {
		// 	off(window, 'scroll', pageScrollThrottle);
		// }
	};

	window.addEventListener('load', function () {
		pageScrollThrottle = throttle(pageScrollHandler, 50, 1000); // 節流作用
		window.addEventListener('scroll', pageScrollThrottle);
		pageScrollHandler();
	});
	/* ---------------------------------------- [END] 整頁 Scroll 監測 (After) */
})(window, document);
